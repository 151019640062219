import React from 'react'
import * as El from './styles'
import { Container } from '../Grid/Container'

const Info = ({ data }) => {
  const html = data.content.childMarkdownRemark.html
  const img = data.image.resolutions.src
  return (
    // <Container>
    <El.Grid>
      <img src={img} alt='info_img' />
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </El.Grid>
    // </Container>
  )
}

export default Info
