import styled from 'styled-components'
import media from '../../utils/media'

export const Grid = styled.div`
  padding-top: 65px;
  padding-left: 70px;
  padding-right: 70px;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  line-height: 28px;
  color: #000;
  img {
    width: 100%;
  }
  ${media.lessThan('md')`
    -ms-flex-direction: column;
    flex-direction: column;
  `};

  ${media.greaterThan('md')`
    max-width: 1940px;
    
    -ms-flex-direction: row;
    flex-direction: row;
    & > img {
      width: 25%;
      height: 25%;
      padding-right: 40px;
    }
    & > div {
      width: 50%;
    }
  `};

  p {
    margin-bottom: 28px;
    ${media.lessThan('sm')`margin-bottom:22px; `};
  }

  ${media.lessThan('sm')`
    padding-left: 16px; 
    padding-right: 16px;
  `};

  ${media.greaterThan('1940px')`
    padding-left: 0;
    padding-right: 0;
    margin-left: calc((100% - 1800px) / 2);
  `};

  a {
    text-decoration: none;
    opacity: 0.4;
    color: #000;
  }

  br {
    opacity: 0;
  }
`
