import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Info from '../components/Info'

export default ({ location, data, pageContext }) => (
  <Layout
    pageTitle={data.allContentfulPageInfo.edges[0].node.pageTitle}
    location={location}
    locale={pageContext.locale}
  >
    <Info data={data.allContentfulPageInfo.edges[0].node} />
  </Layout>
)

export const infoQuery = graphql`
  query($locale: String) {
    allContentfulPageInfo(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          pageTitle
          image {
            title
            resolutions(width: 600) {
              src
            }
          }
          content {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
